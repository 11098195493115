import request from "@/utils/request"; //导入封装请求的js文件

export function login(params = {}) {
  return request({
    url: "/api/users/login", //接口路径
    method: "post", //接口方法
    data: params, //接口参数
  });
}
export function destory(params = {}) {
  return request({
    url: "/api/users/destory", //接口路径
    method: "post", //接口方法
    data: params, //接口参数
  });
}