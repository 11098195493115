<template>
  <div class="box">
    <div class="title">首页热门资讯</div>
    <div class="title">
        <button>编辑</button>
    </div>
    <div class="boder">
      <div class="newsTitle"></div>
      <div class="newsContent"></div>
      <div class="newsImg">
        <a-image
          :width="200"
          src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
</script>
<style scoped>
.title {
  text-align: left;
  font-size: 26px;
  font-weight: bold;
}
</style>