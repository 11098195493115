import request from "@/utils/request"; //导入封装请求的js文件

export function getNews(params = {}) {
  return request({
    url: "/api/news/list", //接口路径
    method: "post", //接口方法
    data: params, //接口参数
  });
}
// /api/news/save
export function saveNews(params = {}) {
  return request({
    url: "/api/news/save", //接口路径
    method: "post", //接口方法
    data: params, //接口参数
  });
}
// 删除新闻
export function delNews(params = {}) {
    return request({
      url: "/api/news/delete", //接口路径
      method: "post", //接口方法
      data: params, //接口参数
    });
  }