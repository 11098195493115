<template>
  <Login v-if="showLogin" />
  <BasicLayout v-else />
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import BasicLayout from './components/BasicLayout.vue';
import Login from "./views/login"
import { destory } from "@/api/users/index";
import { message } from 'ant-design-vue';

const router = useRouter()
const showLogin = ref(false)
router.beforeEach((to, from) => {
  // ...
  // 返回 false 以取消导航
  console.log('to-->', to.path);
  console.log('from-->', from);
  if (to.path === "/login") {
    showLogin.value = true
  } else {
    showLogin.value = false
    destory().then(res => {
      if (res.code === 401) {
        message.error(res.msg)
        router.replace('/login')
      }
    }).catch(() => {
      router.replace('/login')
    })
  }
})


</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  width: 100%;
}
</style>
