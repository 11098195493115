<template>
    <div>
      <div>
        <a-row>
          <a-col :span="20">
            <a-form :model="formState" name="basic">
              <a-row>
                <a-col>
                  <a-form-item label="新闻标题" name="newsTitle">
                    <a-input v-model:value="formState.newsTitle" />
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form>
          </a-col>
          <a-col :span="4">
            <div class="search">
              <a-button type="primary" @click="formSearchHandle">查询</a-button>
              <a-button @click="formResetHandle">重置</a-button>
            </div>
          </a-col>
        </a-row>
      </div>
      <a-divider />
      <div class="add">
        <a-button @click="addHandle" type="primary">新增</a-button>
      </div>
      <!-- 表格 -->
      <div>
        <a-table
          :dataSource="dataSource"
          :columns="columns"
          bordered
          :pagination="false"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'productsImg'">
              <a-image :width="200" :src="record.productsImg" />
            </template>
            <template v-if="column.key === 'content'">
              <div style="width: 200px; height: 60px; overflow-y: auto">
                {{ record.content }}
              </div>
            </template>
            <template v-if="column.key === 'action'">
              <a-popconfirm
                title="确定删除此新闻吗?"
                ok-text="确定"
                cancel-text="取消"
                @confirm="confirm(record)"
              >
                <a-button type="link">删除</a-button>
              </a-popconfirm>
            </template>
          </template>
        </a-table>
        <div class="pages">
          <a-pagination
            v-model:current="current"
            :total="total"
            show-less-items
            @change="pageChangeHandle"
          />
        </div>
      </div>
      <!-- 编辑弹窗 -->
      <a-modal
        v-model:visible="visible"
        width="1200px"
        title="新增产品"
        @ok="handleOk"
        cancelText="取消"
        okText="确定"
      >
        <a-form
          :model="editForm"
          name="新增"
          :label-col="{ span: 2 }"
          :wrapper-col="{ span: 22 }"
          autocomplete="off"
        >
          <a-form-item
            label="产品名称"
            name="newsTitle"
            :rules="[{ required: true, message: '请输入产品名称!' }]"
          >
            <a-input
              v-model:value="editForm.newsTitle"
              placeholder="请输入产品名称"
            />
          </a-form-item>

          <a-form-item
            label="产品简介"
            name="newsContent"
            :rules="[{ required: true, message: '请输入产品简介!' }]"
          >
            <a-textarea
              v-model:value="editForm.newsContent"
              placeholder="请输入产品简介"
              allow-clear
            />
          </a-form-item>
          <a-form-item
            label="产品图片"
            name="productsImg"
            :rules="[{ required: false }]"
          >
            <keep-alive>
              <Upload v-if="visible" />
            </keep-alive>
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
  </template>
  <script setup>
  import { onMounted, provide, reactive, ref } from "vue";
  import Upload from "./compontents/upload.vue";
  import { message } from "ant-design-vue";
  import md5 from "md5";
  import { saveProducts, getProducts, delProducts } from "@/api/products";
  // 筛选列表--------------------------------------------
  const formState = reactive({
    newsTitle: null,
  });
  const formSearchHandle = () => {
    dataSource.length = 0;
    getProducts({
      current: current.value,
    })
      .then((res) => {
        total.value = res.total;
        console.log("data-->", res.data);
        res.data.forEach((item) => {
          dataSource.push({
            code: item.code,
            title: item.title,
            content: item.summary,
            productsImg: item.img,
            creatTime: item.creat_time,
          });
        });
        console.log("dataSource-->", dataSource);
      })
      .catch((err) => {
        message.error(err);
      });
  };
  const formResetHandle = () => {};
  // 新增------------------------------------------------
  const addHandle = () => {
    editForm.newsTitle = null;
    editForm.newsContent = null;
    editForm.productsImg = null;
    visible.value = true;
  };
  // 表格--------------------------------------------------
  const columns = [
    {
      title: "code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "产品名称",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "产品简介",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "产品图片",
      dataIndex: "productsImg",
      key: "productsImg",
    },
    {
      title: "创建时间",
      dataIndex: "creatTime",
      key: "creatTime",
    },
    {
      title: "操作",
      key: "action",
      width: 200,
    },
  ];
  const dataSource = reactive([]);
  // 分页-------------------------------
  // 当前页
  const current = ref(1);
  // 总条数
  const total = ref(0);
  const pageChangeHandle = () => {
    formSearchHandle();
  }
  // 编辑弹窗 -----------------------------------------
  const editForm = reactive({
    newsTitle: null,
    newsContent: null,
    productsImg: null,
  });
  const visible = ref(false);
  const handleOk = () => {
    const param = {
      code: md5(`${editForm.newsTitle}${Date.now()}`),
      title: editForm.newsTitle,
      content: editForm.newsContent,
      img: editForm.productsImg,
    };
    console.log("param-->", param);
    saveProducts(param)
      .then((res) => {
        console.log("re-->", res);
        if (res.code === 200) {
          formSearchHandle();
          visible.value = false;
          message.success("新闻保存成功");
        } else {
          message.error("新闻保存失败");
        }
      })
      .catch((err) => {
        message.error("新闻保存失败");
        visible.value = false;
        message.error(err);
      });
  };
  const getImg = (url) => {
    editForm.productsImg = url;
  };
  const confirm = (record) => {
    const { code } = record;
    delProducts({
      code,
    })
      .then((res) => {
        if (res.code === 200) {
          message.success("删除成功");
        } else {
          message.warning(res.msg);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };
  /**
   * 生命周期 ----------------------------------------
   */
  onMounted(() => {
    formSearchHandle();
  });
  provide("getImg", getImg);
  </script>
  <style>
  .search {
    display: flex;
    justify-content: space-around;
  }
  .add {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
  }
  .pages {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }
  </style>
