<template>
    <a-upload
      v-model:file-list="fileList"
      name="avatar"
      list-type="picture-card"
      class="avatar-uploader"
      :show-upload-list="false"
      action="http://fdadmin.fudianstyle.cn:8088/api/common/upload"
      :before-upload="beforeUpload"
      @change="handleChange"
    >
      <img class="uploadImg" v-if="imageUrl" :src="imageUrl" alt="avatar" />
      <div v-else>
        <loading-outlined v-if="loading"></loading-outlined>
        <plus-outlined v-else></plus-outlined>
        <div class="ant-upload-text">Upload</div>
      </div>
    </a-upload>
  </template>
    <script setup>
  import { PlusOutlined, LoadingOutlined } from "@ant-design/icons-vue";
  import { message } from "ant-design-vue";
  import { inject, onDeactivated, ref } from "vue";
  const getImg = inject('getImg')
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const fileList = ref([]);
  const loading = ref(false);
  const imageUrl = ref("");
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      loading.value = true;
      return;
    }
    if (info.file.status === "done") {
      console.log('info.file--->', info.file.response.data);
        getImg(info.file.response.data)
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (base64Url) => {
        imageUrl.value = base64Url;
        loading.value = false;
      });
    }
    if (info.file.status === "error") {
      loading.value = false;
      message.error("upload error");
    }
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  onDeactivated(() => {
    imageUrl.value = ""
  })
  </script>
    <style>
    .uploadImg {
      display: block;
      overflow: hidden;
      width: 80%;
      height: 80%;
    }
  .avatar-uploader > .ant-upload {
    width: 280px;
    height: 128px;
  }
  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }
  </style>
