import request from "@/utils/request"; //导入封装请求的js文件

export function getInfo(params = {}) {
  return request({
    url: "/api/info/list", //接口路径
    method: "post", //接口方法
    data: params, //接口参数
  });
}
// /api/info/save
export function saveInfo(params = {}) {
  return request({
    url: "/api/info/save", //接口路径
    method: "post", //接口方法
    data: params, //接口参数
  });
}
