import request from "@/utils/request"; //导入封装请求的js文件

export function getProducts(params = {}) {
    return request({
        url: "/api/products/list", //接口路径
        method: "post", //接口方法
        data: params, //接口参数
    });
}

/**
 * 新增产品
 * @param params
 * @returns {*}
 */
export function saveProducts(params = {}) {
    return request({
        url: "/api/products/save", //接口路径
        method: "post", //接口方法
        data: params, //接口参数
    });
}

/**
 * 删除产品
 * @param params
 * @returns {*}
 */
export function delProducts(params = {}) {
    return request({
        url: "/api/products/delete", //接口路径
        method: "post", //接口方法
        data: params, //接口参数
    });
}
