import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/home";
import Banner from "../views/banner";
import Info from "../views/info";
import News from "../views/news";
import HotNews from "../views/hotNews";
import HomeProduct from "../views/products/homeProduct";
import List from "../views/products/list";
import Login from "../views/login";

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/home", redirect: "/" },
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/banner",
      name: "banner",
      component: Banner,
    },
    {
      path: "/info",
      name: "info",
      component: Info,
    },
    {
      path: "/hotNews",
      name: "hotNews",
      component: HotNews,
    },
    {
      path: "/news",
      name: "news",
      component: News,
    },
    {
      path: "/homeProduct",
      name: "homeProduct",
      component: HomeProduct,
    },
    {
      path: "/list",
      name: "list",
      component: List,
    },
  ],
});
