<template>
  <a-table :columns="columns" :data-source="data" bordered :pagination="false">
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'action'">
        <span>
          <a-button type="link" @click="editHandle(record)">编辑</a-button>
        </span>
      </template>
    </template>
  </a-table>
  <a-modal
    v-model:visible="visible"
    title="编辑"
    cancelText="取消"
    okText="确定"
    @ok="handleOk"
  >
    <a-form :model="formState" name="编辑" autocomplete="off">
      <a-form-item label="标题" name="title">
        <a-input v-model:value="formState.title" />
      </a-form-item>
      <a-form-item label="内容" name="content">
        <a-textarea v-model:value="formState.content" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script setup>
import { onMounted, reactive, ref } from "vue";
import { getInfo, saveInfo } from "@/api/info/index";
const columns = [
  {
    title: "ID",
    dataIndex: "code",
    key: "code",
  },
  {
    title: "标题",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "内容",
    dataIndex: "content",
    key: "content",
  },
  {
    title: "操作",
    key: "action",
  },
];
const data = reactive([]);
const editHandle = (record) => {
  const { code, title, content } = record;
  formState["code"] = code;
  formState["title"] = title;
  formState["content"] = content;
  visible.value = true;
};
/**
 * 编辑
 */
const visible = ref(false);
const handleOk = () => {
  visible.value = false;
  console.log("formState-->", formState);
  const param = {
    ...formState,
  };
  saveInfo(param).then((res) => {
    console.log("res--1->", res);
    getInfoHandle()
  });
};
const formState = reactive({
  code: null,
  title: null,
  content: null,
});
const getInfoHandle = () => {
  getInfo().then((res) => {
    console.log("res--->", res.data);
    data.length = 0;
    if (res?.code === 200) {
      res?.data?.forEach((item) => {
        data.push({
          code: item.info_code,
          title: item.info_title,
          content: item.info_content,
        });
      });
    }
  });
};
/**
 * 生命周期
 */
onMounted(() => {
  getInfoHandle();
});
</script>