<template>
  <div class="typePage">
    <a-layout>
      <a-layout-sider
        breakpoint="lg"
        collapsed-width="0"
        @collapse="onCollapse"
        @breakpoint="onBreakpoint"
      >
        <div class="logo">
          <img src="../assets/logo2.png" alt="" />
        </div>
        <a-menu
          :selectedKeys="selectedKeys"
          @click="menuHandle"
          theme="dark"
          mode="inline"
        >
          <a-menu-item key="home">
            <fund-projection-screen-outlined />
            <span class="nav-text">仪表盘</span>
          </a-menu-item>
<!--          <a-menu-item key="info">-->
<!--            <audit-outlined />-->
<!--            <span class="nav-text">企业基本信息</span>-->
<!--          </a-menu-item>-->
          <a-sub-menu key="sub1">
            <template #title>
              <span>
                <project-outlined />
                <span>产品管理</span>
              </span>
            </template>
<!--            <a-menu-item key="homeProduct">首页推荐产品</a-menu-item>-->
            <a-menu-item key="list">产品列表</a-menu-item>
          </a-sub-menu>
<!--          <a-menu-item key="hotNews">-->
<!--            <fire-outlined />-->
<!--            <span class="nav-text">最新资讯</span>-->
<!--          </a-menu-item>-->
          <a-menu-item key="news">
            <read-outlined />
            <span class="nav-text">新闻资讯管理</span>
          </a-menu-item>
          <!-- <a-menu-item key="banner">
            <picture-outlined />
            <span class="nav-text">banner管理</span>
          </a-menu-item> -->
        </a-menu>
      </a-layout-sider>
      <a-layout>
        <a-layout-header :style="{ background: '#fff', padding: 0 }" />
        <a-layout-content :style="{ margin: '24px 16px 0' }">
          <div
            :style="{ padding: '24px', background: '#fff', minHeight: '360px' }"
          >
            <!-- 路由出口 -->
            <!-- 路由匹配到的组件将渲染在这里 -->
            <router-view></router-view>
          </div>
        </a-layout-content>
        <a-layout-footer style="text-align: center">
          KE XIN CHUAN DONG JI XIE
        </a-layout-footer>
      </a-layout>
    </a-layout>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { onMounted, ref } from "vue";
import {
  FundProjectionScreenOutlined,
  // AuditOutlined,
  ProjectOutlined,
  ReadOutlined,
  // PictureOutlined,
  // FireOutlined,
} from "@ant-design/icons-vue";

const router = useRouter();

const selectedKeys = ref(["home"]);
const onCollapse = (collapsed, type) => {
  console.log(collapsed, type);
};
const onBreakpoint = (broken) => {
  console.log(broken);
};
// 点击菜单导航
const menuHandle = (e) => {
  const { key } = e;
  console.log(key);
  selectedKeys.value.length = 0
  selectedKeys.value.push(key)
  router.push({ name: key });
};
onMounted(() => {
  console.log('name-->', router.currentRoute.value.path);
  if (router.currentRoute.value.path === '/') {
    selectedKeys.value.push("home")
  } else {
    selectedKeys.value.push(router.currentRoute.value.name)
  }
  selectedKeys.value.length = 0
  // selectedKeys.value.push(router.name)
})
</script>
<style>
.typePage {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
}
.logo {
  padding: 10px;
}
.logo img {
  display: block;
  width: 100%;
}
#components-layout-demo-responsive .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

[data-theme="dark"] .site-layout-sub-header-background {
  background: #141414;
}
.ant-layout-content {
  overflow-y: auto;
}
</style>
