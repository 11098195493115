import axios from 'axios'
import { message } from 'ant-design-vue';

// const baseURL = "http://127.0.0.1:8088"
const baseURL = "http://fdadmin.fudianstyle.cn:8088"
// 创建axios的对象
const request = axios.create({
    baseURL: baseURL,  //配置固定域名
    timeout: 5000
})

// 请求拦截
// 所有的网络请求都会走这个方法,可以在请求添加自定义内容
request.interceptors.request.use(
    function (config) {
        config.headers.token = window.sessionStorage.getItem('token') || '' // 请求头添加token值
        config.headers.info = 'lxy'  //请求头添加info值
        return config
    },
    function (err) {
        return Promise.request(err)
    }
)

// 响应拦截
// 此处可以根据服务器返回的状态码做相应的数据
request.interceptors.response.use(
    function (response) {
        const res = response
        if (res.status === 500) {
            message.info('系统未登录，请重新登录', '错误')
            return Promise.reject('error')
        } else if (res.errno === 502) {
            message.info('系统内部错误，请联系管理员维护', '错误')
            return Promise.reject('error')
        } else {
            return Promise.resolve(response.data)
        }
    },
    function (err) {
        return Promise.reject(err)
    }
)

// 封装get和post请求
export function get(url, params) {
    return request.get(url, {params})
}

export function post(url, data) {
    return request.post(url, data)
}

export default request;
