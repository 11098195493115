<template>
    <div class="login">
        <div class="box">
            <div class="title">科新后台管理系统</div>
            <div class="sub_title">KE XIN HOU TAI GUAN LI XI TONG</div>
            <div class="login_body">
                <div class="top">用户登录</div>
                <div class="account">
                    <div class="pannel">
                        <a-form :model="formState" name="basic" autocomplete="off" @finish="onFinish">
                            <a-form-item name="username" :rules="[{ required: true, message: '请输入账号!' }]">
                                <a-input style="height: 50px; width:424px;border:1px #438ce2 solid;background:rgba(0, 0, 0, 0);font-size:20px;color:#fff" v-model:value="formState.username" placeholder="账号" />
                            </a-form-item>
                            <a-form-item name="password" :rules="[{ required: true, message: '请输入密码!' }]">
                                <a-input-password style="height: 50px; width:424px;border:1px #438ce2 solid;background:rgba(0, 0, 0, 0)" v-model:value="formState.password" placeholder="密码" />
                            </a-form-item>
                            <a-form-item>
                                <div class="submit">
                                    <a-button style="width: 330px;height:44px;font-size:24px" type="primary"
                                        html-type="submit">登录</a-button>
                                </div>
                            </a-form-item>
                        </a-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { reactive } from 'vue';
import CryptoJS from 'crypto-js'
import { login } from "@/api/users/index";
import { message } from 'ant-design-vue';
import { useRouter } from 'vue-router';
const router = useRouter()
const formState = reactive({
    username: '',
    password: '',
});
const onFinish = values => {
    const account = {
        username:values.username,
        password: CryptoJS.MD5(values.password).toString()
    }
    console.log('account-->', account);
    try {
        const userInfo = JSON.stringify(account)
        console.log('userInfo-->', userInfo);
        const userEncrypted = CryptoJS.AES.encrypt(userInfo, "zf123456*").toString();
        console.log('encrypted-->', userEncrypted);
        login({
            account: userEncrypted
        }).then(res => {
            if(res && res?.code === 200) {
                window.sessionStorage.setItem('token', res.data.token)
                message.success(res.msg)
                router.replace('/home')
            } else {
                message.warning(res.msg)
            }
        }).catch(err => {
            console.log(err);
        })
    } catch (error) {
        console.log(error);
    }
};
</script>
<style>
.ant-input-affix-wrapper > input.ant-input {
    background: rgba(0,0,0,0);
    font-size: 20px;
    color: #4695d4 !important;
}
.ant-input-password-icon {
    color: rgba(255,255,255,1) !important;
}
.login {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: url(../../assets/login_bg.png) no-repeat center;
    background-size: cover;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.box {
    margin-right: 10%;
    width: 574px;
}

.title {
    font-size: 53px;
    height: 80px;
    line-height: 80px;
    color: #00a0ff;
}

.sub_title {
    height: 26px;
    line-height: 26px;
    font-size: 22px;
    color: #00a0ff;
}

.login_body {
    margin-top: 20px;
    width: 574px;
    height: 400px;
    background: url(../../assets/box_bg.png) no-repeat center;
    background-size: cover;
}

.login_body .top {
    font-size: 45px;
    color: #fff;
    padding-top: 38px;
}

.submit {
    display: flex;
    justify-content: center;
    margin-top: 26px;
}

.account {
    display: flex;
    justify-content: center;
    margin-top: 27px;
}

.pannel {
    width: 424px;
}</style>